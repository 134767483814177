import React from "react";
import {Slider} from "../components/Slider";
import useIsMobile from "../isMobile";

const deliveries = [
    {
        title: '20 FPV-дронів 7"',
        description: '20 FPV-дронів з акумуляторами та комплектуючими передали екіпажу підрозділу ЗСУ Velo Boiz. Липень 2024 року.',
        img: '/wp-content/uploads/img-preview.jpg'
    },
    {
        title: '20 FPV-дронів 7"',
        description: '20 FPV дронів з акумуляторами та комплектуючими передали 47 окремій механізованій бригаді (47 ОМБр). Травень 2024 року.',
        img: '/wp-content/uploads/img-preview-1.jpg'
    },
    {
        title: '20 FPV-дронів 7"',
        description: '20 FPV-дронів з акумуляторами та комплектуючими передали екіпажу підрозділу ЗСУ Velo Boiz. Червень 2024 року.',
        img: '/wp-content/uploads/img-preview-2.jpg'
    }
];

const DeliveryItem = ({title, description, img}) => {
    return (<div className={'flex flex-col gap-1'}>
        <img src={img} alt={title} className={'w-100 block'}/>

        <div>
            <h4 className={'text-sm tactic-sans text-dark mb-0.5'}>{title}</h4>
            <p className={'text-xxs text-dark'}>{description}</p>
        </div>
    </div>)
}

export default function Deliveries() {
    const isMobile = useIsMobile();
    return (<div id={'deliveries'} className={'px-1 py-4 lg:px-2 lg:py-6 bg-texture-light text-dark'}>
        <h2 className={'text-lg lg:text-xl uppercase tactic-sans'}>Відвантажили</h2>

        <Slider pagination={false}
                slidesPerView={isMobile ? 1 : 3}
                spaceBetween={20}
                loop={true}
                className={'arrows-on-top'}
                slides={deliveries.map((item, i) => <DeliveryItem {...item} key={i}/>)}/>
    </div>)
}
